import React, { useState, useEffect } from 'react';

const AnalyticalCard = ({ title, quantity }) => {
    return (
        <div className="bg-white rounded-lg overflow-hidden shadow-md p-6">
            <h2 className="text-lg font-bold mb-2">{title}</h2>
            <p className="text-4xl text-gray-900 font-bold">{quantity}</p>
        </div>
    );
};

const DashboardPage = () => {
    const [dailyActiveUsers, setDailyActiveUsers] = useState(0);
    const [dailyNewUsers, setDailyNewUsers] = useState(0);
    const [averageSpentTime, setAverageSpentTime] = useState(0);

    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 30);
    const endDate = currentDate;
    const startDateString = startDate.toISOString().slice(0, 10);
    const endDateString = endDate.toISOString().slice(0, 10);
    useEffect(() => {
        // Fetch data from server when component mounts
        const fetchData = async () => {
            try {
                const averageTimeResponse = await fetch('https://cyasoon.io/iOS/getAverageTime');
                const averageTimeData = await averageTimeResponse.json();
                let minutes =  (averageTimeData[0].totalAvgTime) / 60;
                setAverageSpentTime(Math.floor(minutes)) 
              
                const activeUsersResponse = await fetch(`https://cyasoon.io/iOS/getActiveUsersByDate?startDate=${startDateString}&endDate=${endDateString}`);
                const activeUsersData = await activeUsersResponse.json();
                setDailyActiveUsers(activeUsersData.length);

                const newUsersResponse = await fetch(`https://cyasoon.io/iOS/getCreatedUsersByDate?startDate=${startDateString}&endDate=${endDateString}`);
                const newUsersData = await newUsersResponse.json();
                setDailyNewUsers(newUsersData.length);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        

        fetchData();
    }, []); 

    return (
        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
            <div style={{ width: '900px' }} className="bg-white p-8 rounded shadow-md w-full">
                <h2 className="text-xl font-bold mb-4">Welcome to Cyasoon Analytics</h2>
                <p className="text-gray-600">You are now authenticated and can access the dashboard.</p>
                 
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold mb-4">Analytics for the last 30 days</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        <AnalyticalCard 
                            title="Active Users"
                            quantity={dailyActiveUsers}
                        />
                        <AnalyticalCard 
                            title="New Users"
                            quantity={dailyNewUsers}
                        />
                        <AnalyticalCard 
                            title="Average Spent Time"
                            quantity={averageSpentTime + ' min'}
                        />
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default DashboardPage;